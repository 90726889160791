import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import PropTypes from "prop-types"
import { MDXRenderer } from "gatsby-plugin-mdx"
import SEO from "../seo"
import CaseStudyBanner from "./CaseStudyBanner"
import AllWorks from "./AllWorks"
import TransitionContext from "../../context/TransitionContext"

const WorksddLayout = ({ data: { mdx }, location, pageContext }) => {
  const cropDefault = mdx.twitterCard.homehero.childImageSharp.resize

  return (
    <>
      <SEO
        title={
          mdx.frontmatter.clientName + ` - ` + mdx.frontmatter.projectTitle
        }
        image={cropDefault}
        description={mdx.exports.designParagraphA}
      />
      <TransitionContext location={location}>
        <Wrapper bgColor={mdx.frontmatter.itemColor}>
          <AllWorks pageContext={pageContext} />
          <CaseStudyBanner
            clientName={mdx.frontmatter.clientName}
            homehero={mdx.frontmatter.homehero.childImageSharp.fluid}
            projectTitle={mdx.frontmatter.projectTitle}
            videoSrcURL={mdx.frontmatter.videoSrcUrl}
            widescreen={mdx.frontmatter.widescreen}
            serviceCategory={mdx.frontmatter.serviceCategory}
            clientSite={mdx.frontmatter.clientSite}
          />
          <MDXRenderer frontmatter={mdx.frontmatter}>{mdx.body}</MDXRenderer>
        </Wrapper>
      </TransitionContext>
    </>
  )
}

WorksddLayout.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      body: PropTypes.string,
      twitterCard: PropTypes.shape({
        homehero: PropTypes.object,
      }),
      exports: PropTypes.shape({
        designParagraphA: PropTypes.string,
      }),
      frontmatter: PropTypes.shape({
        className: PropTypes.string,
        clientName: PropTypes.string,
        date: PropTypes.string,
        itemColor: PropTypes.string,
        projectTitle: PropTypes.string,
        serviceCategory: PropTypes.string,
        slug: PropTypes.string,
        softwareTags: PropTypes.arrayOf(PropTypes.string),
        tags: PropTypes.arrayOf(PropTypes.string),
        type: PropTypes.string,
        widescreen: PropTypes.bool,
        hero: PropTypes.object,
        homehero: PropTypes.object,
        lightbox1: PropTypes.object,
        lightbox2: PropTypes.object,
        lightbox3: PropTypes.object,
        lightbox4: PropTypes.object,
        lightbox5: PropTypes.object,
        lightbox6: PropTypes.object,
        lightbox7: PropTypes.object,
        lightbox8: PropTypes.object,
        videoSrcUrl: PropTypes.string,
      }),
    }),
  }).isRequired,
}

export default WorksddLayout

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: content;
  width: 100vw;
  position: relative;
  background-color: ${props => props.bgColor};
`

export const query = graphql`
  query PostQuery2($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug }, type: { eq: "works" } }) {
      body
      fields {
        slug
      }
      twitterCard: frontmatter {
        homehero {
          childImageSharp {
            resize(width: 1024, height: 1024, cropFocus: CENTER) {
              src
              width
              height
            }
          }
        }
      }
      exports {
        designParagraphA
      }
      frontmatter {
        clientSite
        title
        clientName
        projectTitle
        metrics {
          key
          num
          unit
          descr
        }
        serviceCategory
        tags
        softwareTags
        homehero {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        lightbox1 {
          childImageSharp {
            fluid(maxWidth: 800) {
              aspectRatio
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        lightbox2 {
          childImageSharp {
            fluid(maxWidth: 800) {
              aspectRatio
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        lightbox3 {
          childImageSharp {
            fluid(maxWidth: 800) {
              aspectRatio
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        lightbox4 {
          childImageSharp {
            fluid(maxWidth: 800) {
              aspectRatio
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        lightbox5 {
          childImageSharp {
            fluid(maxWidth: 800) {
              aspectRatio
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        lightbox6 {
          childImageSharp {
            fluid(maxWidth: 800) {
              aspectRatio
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        lightbox7 {
          childImageSharp {
            fluid(maxWidth: 800) {
              aspectRatio
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        lightbox8 {
          childImageSharp {
            fluid(maxWidth: 800) {
              aspectRatio
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        section1dd {
          childImageSharp {
            fluid(maxWidth: 800) {
              aspectRatio
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        section2dd {
          childImageSharp {
            fluid(maxWidth: 800) {
              aspectRatio
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        softwareTags
        videoSrcUrl
        itemColor
        widescreen
      }
    }
  }
`
